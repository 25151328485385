// @flow
import React, {
    useState, useEffect, useCallback, useRef, useMemo,
} from 'react';
import type { Node } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from 'react-sidebar';
import Select from 'react-select';

import Input from 'react-toolbox/lib/input/Input';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon/FontIcon';
import Autocomplete from 'react-toolbox/lib/autocomplete';
import List from 'react-toolbox/lib/list/List';
import ListItem from 'react-toolbox/lib/list/ListItem';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import { saveAs } from 'file-saver';
import fakeUuid from '../Utils/fakeUuid';
import {
    createTask,
    createSubtask,
    createSection,
    subtaskTypes,
    subtaskAttributes,
    multipleOptionsUiTypes,
    subtaskTypeLabel,
    useClickedAway,
    loadSavedWorkflow,
    saveWorkflow,
    transformTemplateToWorkflow,
    isNameAvailable,
    attributesToFieldProps,
    getFollowerPreferencesFromTemplate,
} from '../Utils/workflow';
import { parseForCreation } from '../Utils/referenceData';
import { loadDonorNetPDFFields } from '../Redux/DonorActions';
import {
    createWorkflow, updateWorkflow, setBuilderData, updateWorkflowPreferences, createWorkflowPreferences,
} from '../Redux/ReferenceDataActions';
import type { BuilderMode } from '../Redux/ReferenceDataActions';
import { moveArrayItem } from '../Utils/arrays';
import WorkflowPreview from '../Components/WorkflowPreview';
import ApplicationStyles from '../Themes/ApplicationStyles';
import FormPreview from '../Components/FormPreview';
import Colors from '../Themes/Colors';
import type {
    MainTask, SubtaskType, TaskSectionType, FieldOption, EditorWorkflow, FollowerPreference,
} from '../Utils/types';
import WindowPortal from '../Components/WindowPortal';
import { setSagaMessage } from '../Redux/ApplicationActions';
import SagaMessage from '../Components/SagaMessage';
import { isUserAdmin } from '../Utils/user';
import CasePreferences from '../Components/CasePreferences';
import CasePreferencesStyles from '../Components/Styles/CasePreferencesStyles';

const genericSagaToast: number = 10007;

export default function WorkflowBuilderPage() {
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [workflowName, setWorkflowName] = useState('');
    const [mainTasks, setMainTasks] = useState<MainTask[]>([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [saveCompleted, setSaveCompleted] = useState(false);
    const [newTaskName, setNewTaskName] = useState('');
    const [selectedTask, setSelectedTask] = useState<MainTask>(createTask());
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isFormPreviewOpen, setIsFormPreviewOpen] = useState(false);
    const [mainTaskPreview, setMainTaskPreview] = useState<MainTask>({});
    const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false);
    const [isModifyDialog, setIsModifyDialog] = useState(false);
    const [isCasePreferencesOnlyDialog, setIsCasePreferencesOnlyDialog] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isDuplicateName, setIsDuplicateName] = useState(false);
    const [previousWf, setPreviousWf] = useState(false);
    const [followerPreferences, setFollowerPreferences] = useState<FollowerPreference[]>([]);
    const [preferencesId, setPreferencesId] = useState<number | null>(null);

    const dispatch = useDispatch();

    const hiddenFileInput: {current: null | HTMLInputElement} = React.createRef<HTMLInputElement>();

    const userProfile = useSelector((state) => state.auth.profile);
    const {
        organizationId: userOrgId,
        userId,
    } = userProfile;
    const isAdmin = isUserAdmin(userProfile.accessLevel);

    const organizations = useSelector((state) => state.organization.organizations);
    const organizationSearch = organizations.map((org) => org.organizationName);
    // Non-admins should only be able to select their organization
    if (!isAdmin && (selectedOrganization === '' || selectedOrganization == null)) {
        const userOrganization = organizations.find((org) => org.organizationId === userOrgId);
        setSelectedOrganization(userOrganization?.organizationName);
    }

    const loadingUpdateWorkflowPreferences = useSelector((state) => state.loading.updateWorkflowPreferences);
    const allDonorNetPDFFields = useSelector((state) => state.donor.fields);
    const donorNetPDFFields = allDonorNetPDFFields && allDonorNetPDFFields.fields ? allDonorNetPDFFields.fields.filter((field) => field.workflowField) : [];

    const forms = useSelector((state) => state.reference.forms);
    const availableForms = useMemo(() => forms.filter((form) => form.visible && !form.inactive), [forms]);
    const workflows = useSelector((state) => state.reference.workflows);
    const builderMode = useSelector((state) => state.reference.builder.mode);
    const workflowKey = useSelector((state) => state.reference.builder.workflow);
    const isModifyTemplate = builderMode === 'update';
    const isModifyPreferences = builderMode === 'preferences';
    const availableWorkflows = useMemo(() => (isAdmin
        ? workflows.filter((workflow) => workflow.visible && !workflow.inactive)
        : workflows.filter((workflow) => workflow.ownerId === userOrgId && workflow.visible && !workflow.inactive)), [isAdmin, workflows, userOrgId]);
    const completeWorkflows = useMemo(() => availableWorkflows.map((workflow) => ({
        ...workflow,
        forms: workflow.tasks.map((task) => availableForms.find((form) => form.key === task.details.formId)).filter(Boolean),
    })), [availableWorkflows, availableForms]);
    const availableNames = useMemo(() => completeWorkflows.map((workflow) => workflow.name), [completeWorkflows]);

    const getWorkflowJson = (): EditorWorkflow => {
        const organization = organizations.find((org) => org.organizationName === selectedOrganization);
        return {
            key: workflowKey,
            ownerType: 'Organization',
            ownerId: organization?.organizationId,
            selectedOrganization,
            workflowName,
            mainTasks,
        };
    };

    const validChanges = JSON.stringify(getWorkflowJson()) !== JSON.stringify(previousWf);

    // load saved workflow if present
    useEffect(() => {
        const workflow = loadSavedWorkflow(userId);

        if (workflow) {
            setSelectedOrganization(workflow.selectedOrganization);
            setWorkflowName(workflow.workflowName);
            setMainTasks(workflow.mainTasks);
        }
    }, [userId]);

    // Check for duplicate name as the name value changes
    useEffect(() => {
        setIsDuplicateName(!isNameAvailable(workflowName, availableNames));
    }, [workflowName, availableNames]);

    // save workflow
    useEffect(() => {
        saveWorkflow(userId, getWorkflowJson());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganization, workflowName, mainTasks]);

    const closeDialog = useCallback(() => {
        setIsTemplateDialogOpen(false);
        setSelectedTemplate(null);
    }, [setIsTemplateDialogOpen, setSelectedTemplate]);

    const loadTemplate = useCallback((mode, name) => {
        dispatch(setBuilderData({
            mode,
            workflow: selectedTemplate?.key ?? '',
        }));
        setWorkflowName(name);
        setPreviousWf(getWorkflowJson());
        closeDialog();
    }, [dispatch, selectedTemplate, setWorkflowName, closeDialog]);

    // Update workflow tasks when builder workflow key changes
    useEffect(() => {
        const template = completeWorkflows.find((wf) => workflowKey === wf.key);

        if (template && template.forms.length > 0) {
            const workflow = transformTemplateToWorkflow(template);
            const followers = getFollowerPreferencesFromTemplate(template);
            if (template.casePreferences) {
                setPreferencesId(template.casePreferences.id);
            }

            setMainTasks(workflow.tasks);
            setFollowerPreferences(followers);
        }
    }, [workflowKey, completeWorkflows]);

    useEffect(() => {
        dispatch(loadDonorNetPDFFields());
    }, [dispatch]);

    const addMainTask = () => {
        setMainTasks([...mainTasks, createTask({ name: newTaskName, })]);
        setNewTaskName('');

        setSelectedTask(createTask());
    };

    const previewWorkflow = () => {
        setIsPreviewOpen(true);
    };

    const onDownloadWorkflow = () => {
        const organization = organizations.find((org) => org.organizationName === selectedOrganization);
        const orgCode = organization.organizationCode;
        const orgId = organization.organizationId;

        const workflow = {
            domain: 'WORKFLOW',
            key: `${orgCode}-DRAFT`,
            comment: workflowName,
            reference_data: {
                name: `${orgCode}-DRAFT`,
                id: `${orgId}xxx`,
                type: 'Donor',
                flat: true,
                progressBar: true,
                ownerType: 'Organization',
                ownerId: orgId,
                tasks: mainTasks.map((task) => ({
                    id: `${orgId}xxxxxx`,
                    description: task.name,
                    formId: `${orgCode}-XXX`,
                })),
                trackers: [],
            },
        };

        const workflowJSON = JSON.stringify(workflow, null, ' ');
        const fileName = `${orgCode}-DRAFT.json`;
        const blob = new Blob([workflowJSON], { type: 'application/json', });
        saveAs(blob, fileName);
    };

    const onDownloadForms = () => {
        const organization = organizations.find((org) => org.organizationName === selectedOrganization);
        const orgCode = organization.organizationCode;

        mainTasks.forEach((task) => {
            const form = {
                domain: 'FORM',
                key: `${orgCode}-XXX`,
                reference_data: {
                    formId: `${orgCode}-XXX`,
                    behaviors: {
                        lockWhenFinalized: true,
                        saveOnComplete: true,
                        clearOnIncomplete: false,
                        clearOnNotApplicable: true,
                    },
                    sections: [],
                },
            };
            task.sections.forEach((section) => {
                const sectionId = form.reference_data.sections.length + 1;
                const fieldId = sectionId * 100;
                const formSection = {
                    id: form.reference_data.sections.length + 1,
                    title: section.title,
                    fields: [],
                };
                section.fields.forEach((subtask, subIdx) => {
                    const newAttributes = attributesToFieldProps(subtask.attributes, subtask.uiType);
                    const subTaskMapping: ?string = subtask.mapping;
                    formSection.fields.push({
                        id: fieldId + subIdx,
                        title: subtask.title,
                        type: (subtask.uiType === 'switch' || subtask.uiType === 'check') ? 'boolean' : 'string',
                        uiType: subtask.uiType,
                        responseOptions: subtask?.responseOptions,
                        displayAs: subtask.displayAs ? subtask.displayAs : subtask.title,
                        ...newAttributes,
                        ...(subTaskMapping ? { mapping: subTaskMapping, } : {}), // only add mapping value if subtask.mapping is defined
                    });
                });
                form.reference_data.sections.push(formSection);
            });
            const formJSON = JSON.stringify(form, null, ' ');
            const taskName = task.name.replace(/\s/g, '');
            const fileName = `${orgCode}-${taskName}.json`;
            const blob = new Blob([formJSON], { type: 'application/json', });
            saveAs(blob, fileName);
        });
    };

    const onOpenEdit = (task) => {
        setSelectedTask(task);
        setOpenEdit(true);
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
        setSaveCompleted(false);
    };

    const onUploadBuilderFileClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const setSavedWorkflowBuilderValues = (editorWorkflow: any) => {
        if (editorWorkflow.selectedOrganization) {
            setSelectedOrganization(editorWorkflow.selectedOrganization);
        }

        if (editorWorkflow.workflowName) {
            setWorkflowName(editorWorkflow.workflowName);
        }

        if (editorWorkflow.mainTasks) {
            setMainTasks(editorWorkflow.mainTasks);
        }
    };

    const handleBuilderFileUpload = () => {
        if (hiddenFileInput.current && hiddenFileInput.current.files && hiddenFileInput.current.files.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    if (typeof reader.result === 'string') {
                        const fileRead = JSON.parse(reader.result);
                        setSavedWorkflowBuilderValues(fileRead);
                    }
                } catch (error) {
                    const errorMsg = error.message ? error.message : 'There was an error uploading the file.';
                    dispatch(setSagaMessage('Error', errorMsg, 'OK', true));
                }
            };
            reader.readAsText(hiddenFileInput.current.files[0]);
        }
    };

    const downloadBuilderFile = () => {
        const organization = organizations.find((org) => org.organizationName === selectedOrganization);
        const orgCode = organization ? organization.organizationCode : '';
        const progressJSON = JSON.stringify({
            selectedOrganization,
            workflowName,
            mainTasks,
        });

        const blob = new Blob([progressJSON], { type: 'application/json', });
        const fileName = orgCode ? `${orgCode}-WORKFLOW-BUILDER.json` : 'WORKFLOW-BUILDER.json';
        saveAs(blob, fileName);
    };

    const deleteTask = (task) => {
        const taskId = task.id;

        setMainTasks(mainTasks.filter((t) => t.id !== taskId));

        // Remove task from follower preferences if deleting the task
        if (followerPreferences) {
            const newFollowerPreferences = [...followerPreferences];
            followerPreferences.forEach((follower, index) => {
                if (follower.tasks.includes(taskId)) {
                    const newFollowerTasks = follower.tasks.filter((t) => t !== taskId);
                    newFollowerPreferences[index].tasks = newFollowerTasks;
                }
            });
            setFollowerPreferences(newFollowerPreferences);
        }
    };

    const saveTask = (taskData: MainTask) => {
        const taskId = taskData.id;

        setMainTasks(mainTasks.map((task) => (task.id === taskId ? taskData : task)));
        setOpenEdit(false);
        setSaveCompleted(false);
    };

    const selectedTaskActions = [
        { label: 'Cancel', onClick: onCloseEdit, },
        { label: 'Save Task', onClick: () => setSaveCompleted(true), }
    ];

    const openSubtask = (mainTask) => {
        setMainTaskPreview(mainTask);
        setIsFormPreviewOpen(true);
    };

    const moveTask = (task, newIndex) => {
        const taskId = task.id;
        const currentIndex = mainTasks.findIndex((t) => t.id === taskId);

        setMainTasks(moveArrayItem<MainTask>(mainTasks, currentIndex, newIndex));
    };

    const validateFollowerNames = () => {
        for (let i = 0; i < followerPreferences.length; i += 1) {
            if (!followerPreferences[i].name) {
                dispatch(setSagaMessage('Error', 'Please ensure all follower groups have names', 'OK', true));
                return false;
            }
        }

        return true;
    };


    const handleCreateWorkflow = () => {
        const wf = parseForCreation(getWorkflowJson());
        const validFollowerNames = validateFollowerNames();
        if (!validFollowerNames) {
            return;
        }

        if (isModifyTemplate) {
            dispatch(updateWorkflow(wf, followerPreferences));
            setPreviousWf(getWorkflowJson());
        } else {
            dispatch(createWorkflow(wf, followerPreferences));
            setPreviousWf(getWorkflowJson());
        }
    };

    const handleUpdatePreferences = () => {
        // Ensure all followers have names
        const validFollowerNames = validateFollowerNames();
        if (!validFollowerNames) {
            return;
        }
        // Update if preferenes id is defined, otherwise create them
        if (preferencesId) {
            dispatch(updateWorkflowPreferences(preferencesId, followerPreferences));
        } else {
            dispatch(createWorkflowPreferences(workflowKey, followerPreferences));
        }
    };

    const handleTemplateSelection = (templateKey) => {
        const template = completeWorkflows.find((workflow) => workflow.key === templateKey);

        setSelectedTemplate(template);
    };

    const onTemplateOpen = (isModifying: boolean, casePreferencesOnly: boolean) => {
        setIsModifyDialog(isModifying);
        setIsCasePreferencesOnlyDialog(casePreferencesOnly);
        setIsTemplateDialogOpen(true);
    };

    const updateFollowerPreferences = (follower: FollowerPreference, index: number) => {
        const newFollowers = [...followerPreferences];
        newFollowers[index] = follower;
        setFollowerPreferences(newFollowers);
    };

    const addNewFollowerPreference = () => {
        const emptyFollower: FollowerPreference = {
            id: fakeUuid(),
            name: '',
            members: [],
            teams: [],
            tasks: [],
            canEdit: false,
        };

        const newFollowers = [...followerPreferences];
        newFollowers.push(emptyFollower);
        setFollowerPreferences(newFollowers);
    };

    const deleteFollowerPreference = (index: number) => {
        const newFollowers = [...followerPreferences];
        newFollowers.splice(index, 1);
        setFollowerPreferences(newFollowers);
    };

    const previewComponent = isFormPreviewOpen
        ? (
            <FormPreview mainTask={mainTaskPreview} onClosePreview={() => setIsFormPreviewOpen(false)} />
        ) : (
            <WorkflowPreview
                tasks={mainTasks}
                organizationName={selectedOrganization}
                onClosePreview={() => setIsPreviewOpen(false)}
                openSubtask={openSubtask}
            />
        );

    const getMainTaskView = () => {
        if (isModifyPreferences) {
            return (
                <div className="workflow-editor" style={ApplicationStyles.mainTaskHeading}>
                    <h2>Tasks</h2>
                    <div style={ApplicationStyles.mainTaskItem}>
                        {
                            mainTasks.length > 0
                                ? (
                                    <List className="task-list">
                                        {
                                            mainTasks.map((task) => (
                                                <ListItem
                                                    key={task.id}
                                                    className="task-item"
                                                    caption={task.name}
                                                    ripple={false}
                                                />
                                            ))
                                        }
                                    </List>
                                )
                                : null
                        }
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="workflow-editor" style={ApplicationStyles.mainTaskHeading}>
                    <h2>Tasks</h2>
                    <div style={ApplicationStyles.mainTaskItem}>
                        {
                            mainTasks.length > 0
                                ? (
                                    <List className="task-list">
                                        {
                                            mainTasks.map((task, taskIndex) => (
                                                <ListItem
                                                    key={task.id}
                                                    className="task-item"
                                                    caption={task.name}
                                                    ripple={false}
                                                    selectable
                                                    onClick={() => onOpenEdit(task)}
                                                    leftActions={[
                                                        <FontIcon
                                                            key={`${task.id}-up`}
                                                            value="arrow_upward"
                                                            className={`arrow ${taskIndex > 0 ? 'active' : 'inactive'}`}
                                                            onClick={
                                                                () => taskIndex > 0 && moveTask(task, taskIndex - 1)
                                                            }
                                                        />,
                                                        <FontIcon
                                                            key={`${task.id}-down`}
                                                            value="arrow_downward"
                                                            className={`arrow ${taskIndex < mainTasks.length - 1 ? 'active' : 'inactive'}`}
                                                            onClick={
                                                                () => taskIndex < mainTasks.length - 1 && moveTask(task, taskIndex + 1)
                                                            }
                                                        />
                                                    ]}
                                                    rightActions={[
                                                        <Button
                                                            key={`${task.id}-delete`}
                                                            icon="delete"
                                                            flat
                                                            onClick={
                                                                () => deleteTask(task)
                                                            }
                                                        />
                                                    ]}
                                                />
                                            ))
                                        }
                                    </List>
                                )
                                : <div style={ApplicationStyles.noMainTasks}>Add main task to workflow below ...</div>
                        }
                    </div>
                </div>
                <div style={ApplicationStyles.addMainTasks}>
                    <Input
                        type="text"
                        label="Add Main Task"
                        value={newTaskName}
                        onChange={(newValue) => setNewTaskName(newValue)}
                    />
                    <Button
                        style={newTaskName.length > 0 ? ApplicationStyles.button : ApplicationStyles.buttonDisabled}
                        label="Add"
                        onClick={addMainTask}
                        disabled={newTaskName.length < 1}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {isPreviewOpen
                ? (
                    <WindowPortal>
                        {previewComponent}
                    </WindowPortal>
                ) : null}
            <Sidebar
                sidebar={previewComponent}
                open={isPreviewOpen}
                docked={isPreviewOpen}
                pullRight
                sidebarClassName="rightSidebar"
                styles={{
                    sidebar: {
                        background: 'white',
                        marginTop: 'var(--header-height)',
                        zIndex: 1,
                    },
                }}
            >
                <div style={ApplicationStyles.pagePadding}>
                    <SagaMessage toastId={genericSagaToast} />
                    <div style={ApplicationStyles.workflowHeaderButtons}>
                        <Button
                            style={ApplicationStyles.button}
                            label="Preview Workflow"
                            onClick={previewWorkflow}
                        />
                        <Button
                            style={selectedOrganization ? ApplicationStyles.button : ApplicationStyles.buttonDisabled}
                            label="Download Workflow"
                            onClick={onDownloadWorkflow}
                            disabled={!selectedOrganization}
                        />
                        <Button
                            style={selectedOrganization ? ApplicationStyles.button : ApplicationStyles.buttonDisabled}
                            label="Download Forms"
                            onClick={onDownloadForms}
                            disabled={!selectedOrganization}
                        />
                    </div>
                    <div style={ApplicationStyles.workflowHeaderButtons}>
                        <Button
                            style={ApplicationStyles.button}
                            label="Save Progress"
                            onClick={downloadBuilderFile}
                        />
                        <Button
                            style={ApplicationStyles.button}
                            label="Upload Progress File"
                            onClick={onUploadBuilderFileClick}
                        />
                        <input
                            style={{ display: 'none', }}
                            id="files"
                            type="file"
                            accept=".json"
                            ref={hiddenFileInput}
                            onChange={handleBuilderFileUpload}
                        />
                        <Button
                            style={ApplicationStyles.button}
                            label="Use Existing Template"
                            onClick={() => onTemplateOpen(false, false)}
                        />
                        <Button
                            style={ApplicationStyles.button}
                            label="Modify Template"
                            onClick={() => onTemplateOpen(true, false)}
                        />
                        <Button
                            style={ApplicationStyles.button}
                            label="Modify Case Preferences"
                            onClick={() => onTemplateOpen(true, true)}
                        />
                    </div>
                    <div style={ApplicationStyles.workflowSelection}>
                        {isAdmin ? (
                            <Autocomplete
                                direction="down"
                                selectedPosition="above"
                                label="Organization"
                                multiple={false}
                                onChange={setSelectedOrganization}
                                source={organizationSearch}
                                value={selectedOrganization}
                            />
                        ) : (
                            <p>
                                Organization:
                                {' '}
                                {selectedOrganization}
                            </p>
                        )}
                    </div>
                    <div style={ApplicationStyles.workflowSelection}>
                        <Input
                            type="text"
                            label="Workflow Name"
                            value={workflowName}
                            disabled={isModifyTemplate || isModifyPreferences}
                            onChange={setWorkflowName}
                        />
                    </div>
                    <div style={ApplicationStyles.flexStart}>
                        {getMainTaskView()}
                        <CasePreferences
                            followerPreferences={followerPreferences}
                            mainTasks={mainTasks}
                            onFollowerChange={updateFollowerPreferences}
                            onAddNewFollower={addNewFollowerPreference}
                            onFollowerDelete={deleteFollowerPreference}
                        />
                    </div>
                    <div style={CasePreferencesStyles.saveButtonContainer}>
                        {isModifyPreferences ? (
                            <Button
                                disabled={loadingUpdateWorkflowPreferences}
                                style={loadingUpdateWorkflowPreferences ? ApplicationStyles.buttonDisabled : ApplicationStyles.button}
                                label="Update Preferences"
                                onClick={handleUpdatePreferences}
                            />
                        ) : (
                            <Button
                                style={
                                    isModifyTemplate ? ((selectedOrganization.trim() === '' || !validChanges)
                                        ? ApplicationStyles.buttonDisabled : ApplicationStyles.button)
                                        : isDuplicateName || !workflowName.trim() || !validChanges ? ApplicationStyles.buttonDisabled : ApplicationStyles.button
                                }
                                label={`${isModifyTemplate ? 'Update' : 'Create'} Workflow`}
                                onClick={handleCreateWorkflow}
                                disabled={isModifyTemplate
                                    ? (selectedOrganization.trim() === '' || !validChanges)
                                    : (isDuplicateName || !workflowName.trim() || !validChanges)}
                            />
                        )}
                    </div>
                </div>
            </Sidebar>
            <div>
                <Dialog
                    active={openEdit}
                    type="normal"
                    title="Edit Task"
                    onEscKeyDown={onCloseEdit}
                    actions={selectedTaskActions}
                >
                    <TaskForm task={selectedTask} onSubmit={saveTask} onSave={saveCompleted} donorNetPDFFields={donorNetPDFFields} />
                </Dialog>
            </div>
            <TemplateDialog
                isOpen={isTemplateDialogOpen}
                isModifyDialog={isModifyDialog}
                isCasePreferencesOnlyDialog={isCasePreferencesOnlyDialog}
                closeDialog={closeDialog}
                selectedTemplate={selectedTemplate}
                workflows={completeWorkflows}
                onTemplateSelect={handleTemplateSelection}
                availableNames={availableNames}
                isNameAvailable={isNameAvailable}
                loadTemplate={loadTemplate}
            />
        </>
    );
}

function TemplateDialog({
    isOpen,
    closeDialog,
    isModifyDialog,
    isCasePreferencesOnlyDialog,
    availableNames,
    selectedTemplate,
    workflows,
    onTemplateSelect,
    loadTemplate,
}: {
    isOpen: boolean,
    closeDialog: () => void,
    isModifyDialog: boolean,
    isCasePreferencesOnlyDialog: boolean,
    availableNames: string[],
    selectedTemplate: any,
    workflows: any,
    onTemplateSelect: (string) => void,
    loadTemplate: (mode: BuilderMode, name: string) => void,
}) {
    const [title, setTitle] = useState('');
    const [actions, setActions] = useState([]);
    const [name, setName] = useState('');
    const [isDuplicateName, setIsDuplicateName] = useState(false);
    const [templates, setTemplates] = useState([]);

    const currentTemplateKey = selectedTemplate?.key ?? '';

    // Create options for the template dropdown
    useEffect(() => {
        setTemplates(workflows.sort((a, b) => a.name.localeCompare(b.name)).reduce((temps, workflow) => {
            temps[workflow.key] = `${workflow.name} (${workflow.key})`;
            return temps;
        }, {}));
    }, [workflows]);

    // Check for duplicate name as the name value changes
    useEffect(() => {
        setIsDuplicateName(!isNameAvailable(name, availableNames));
    }, [name, availableNames]);

    // Clear out name when dialog first opens
    useEffect(() => {
        if (isOpen) {
            setName('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (isModifyDialog) {
            setTitle('Select a Template to Modify');
            if (isCasePreferencesOnlyDialog) {
                setActions([
                    { label: 'Cancel', onClick: closeDialog, },
                    { label: 'Modify Preferences', onClick: () => loadTemplate('preferences', selectedTemplate.name), disabled: !selectedTemplate, }
                ]);
            } else {
                setActions([
                    { label: 'Cancel', onClick: closeDialog, },
                    { label: 'Modify Template', onClick: () => loadTemplate('update', selectedTemplate.name), disabled: !selectedTemplate, }
                ]);
            }
        } else {
            const disabled = isDuplicateName || !selectedTemplate || name.trim().length < 1;
            setTitle('Select a Template');
            setActions([
                { label: 'Cancel', onClick: closeDialog, },
                { label: 'Load Template', onClick: () => loadTemplate('create', name), disabled, }
            ]);
        }
    }, [isModifyDialog, selectedTemplate, isDuplicateName, name, closeDialog, loadTemplate]);

    const getNameOrDescriptiveText = () => {
        if (isModifyDialog) {
            if (isCasePreferencesOnlyDialog) {
                return (<p>Choose a workflow to modify its follower preferences.</p>);
            }
            return (<p>Modify a current workflow and save as a new version, deactivating the previous one.</p>);
        }

        return (
            <Input
                type="text"
                label="New Workflow Name"
                onChange={(newName) => setName(newName)}
                value={name}
            />
        );
    };

    return (
        <Dialog
            active={isOpen}
            type="normal"
            className="templateDialog"
            title={title}
            onEscKeyDown={closeDialog}
            actions={actions}
        >
            <Autocomplete
                auto
                multiple={false}
                key={currentTemplateKey}
                label="Select a Workflow"
                onChange={onTemplateSelect}
                source={templates}
                value={currentTemplateKey}
                required
            />
            {getNameOrDescriptiveText()}
            {name.length > 0 && isDuplicateName && <p style={{ color: 'red', }}>This workflow name has been previously used. Please enter a new name.</p>}
        </Dialog>
    );
}

function TaskForm({
    task, onSubmit, onSave, donorNetPDFFields,
}: {task: MainTask, onSubmit: (task: MainTask) => void, onSave: boolean, donorNetPDFFields: any }) {
    const [name, setName] = useState<string>(task.name);
    const [sections, setSections] = useState<TaskSectionType[]>([...task.sections]);
    const [selectedSection, setSelectedSection] = useState<TaskSectionType | void>();


    const saveSection = (sectionData: TaskSectionType) => {
        const sectionId = sectionData.id;

        setSections(sections.map((section) => (section.id === sectionId ? sectionData : section)));
        setSelectedSection(undefined);
    };

    const addSection = useCallback(() => {
        const newSection = createSection();

        setSections([...sections, newSection]);
        setSelectedSection(newSection);
    }, [sections]);

    const moveSection = (section, newIndex) => {
        const sectionId = section.id;
        const currentIndex = sections.findIndex((s) => s.id === sectionId);

        setSections(moveArrayItem<TaskSectionType>(sections, currentIndex, newIndex));
    };

    const deleteSection = (section) => {
        const sectionId = section.id;

        setSections(sections.filter((s) => s.id !== sectionId));
    };

    const handleSelectSection = (section: TaskSectionType) => {
        setSelectedSection(section);
    };


    if (onSave) {
        onSubmit(createTask({
            id: task.id,
            name,
            sections,
        }));
    }

    // Create a new section if none
    useEffect(() => {
        if (sections.length === 0) {
            addSection();
        }
    }, [task, sections, addSection]);


    return (
        <>
            <div style={ApplicationStyles.addMainTasks}>
                <Input
                    type="text"
                    label="Task Name"
                    value={name}
                    onChange={setName}
                />
            </div>
            <div>
                <div style={ApplicationStyles.mainTaskHeading}>
                    <ul className="task-section-list">
                        {sections.map((section, sectionIdx) => (
                            <li key={section.id}>
                                {section.id === selectedSection?.id
                                    ? <SectionForm key={section.id} section={section} onSubmit={saveSection} donorNetPDFFields={donorNetPDFFields} />
                                    : (
                                        <Section
                                            key={section.id}
                                            onSelect={() => handleSelectSection(section)}
                                            section={section}
                                            leftActions={[
                                                <FontIcon
                                                    key={`${section.id}-up`}
                                                    value="arrow_upward"
                                                    className={`arrow ${sectionIdx > 0 ? 'active' : 'inactive'}`}
                                                    onClick={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            return sectionIdx > 0 && moveSection(section, sectionIdx - 1);
                                                        }
                                                    }
                                                />,
                                                <FontIcon
                                                    key={`${section.id}-down`}
                                                    value="arrow_downward"
                                                    className={`arrow ${sectionIdx < sections.length - 1 ? 'active' : 'inactive'}`}
                                                    onClick={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            return sectionIdx < sections.length - 1 && moveSection(section, sectionIdx + 1);
                                                        }
                                                    }
                                                />
                                            ]}
                                            rightActions={[
                                                <Button
                                                    key={`${task.id}-delete`}
                                                    icon="delete"
                                                    flat
                                                    onClick={
                                                        () => deleteSection(section)
                                                    }
                                                />
                                            ]}
                                        />
                                    )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div style={ApplicationStyles.editTaskFooter}>
                <Button
                    onClick={addSection}
                    icon="add"
                    floating
                    style={{
                        backgroundColor: Colors.brandPrimary,
                        color: 'white',
                    }}
                    disabled={typeof selectedSection !== 'undefined'}
                />
            </div>
        </>
    );
}

function SectionForm(
    { section, onSubmit, donorNetPDFFields, }: { section: TaskSectionType, onSubmit: (section: TaskSectionType) => void, donorNetPDFFields: any }
) {
    const [title, setTitle] = useState<string>(section.title);
    const [subtasks, setSubtasks] = useState([...section.fields]);
    const [selectedSubtask, setSelectedSubtask] = useState<SubtaskType | void>(undefined);
    const { ref, clickedAway, } = useClickedAway();

    const saveSection = () => onSubmit(createSection({
        id: section.id,
        title,
        fields: subtasks,
    }));

    const saveSubtask = useCallback((subtaskToUpdate: SubtaskType) => {
        const subtaskToUpdateId = subtaskToUpdate.id;

        setSubtasks(subtasks.map((subtask) => (subtask.id === subtaskToUpdateId ? subtaskToUpdate : subtask)));
    }, [subtasks, setSubtasks]);

    const addSubtask = () => {
        const newSubtask = createSubtask();
        setSubtasks([...subtasks, newSubtask]);
        setSelectedSubtask(newSubtask);
    };

    const handleSelectSubtask = (subtask: SubtaskType) => {
        setSelectedSubtask(subtask);
    };

    const handleDeleteSubtask = (subtask: SubtaskType) => {
        const subtaskId = subtask.id;
        setSubtasks(subtasks.filter((sub) => sub.id !== subtaskId));

        if (selectedSubtask?.id === subtaskId) {
            setSelectedSubtask(undefined);
        }
    };

    const moveSubtask = (subtask: SubtaskType, newIndex: number) => {
        const subtaskId = subtask.id;
        const currentIndex = subtasks.findIndex((sub) => sub.id === subtaskId);

        setSubtasks(moveArrayItem<SubtaskType>(subtasks, currentIndex, newIndex));
    };

    useEffect(() => {
        if (clickedAway) {
            saveSection();
        }
        // eslint-disable-next-line
    }, [clickedAway]);

    return (
        <div ref={ref}>
            <Input
                type="text"
                label="Section Title (optional)"
                onChange={setTitle}
                value={title}
            />

            {subtasks.map((subtask, subtaskIndex) => (
                selectedSubtask?.id === subtask.id
                    ? <SubtaskForm key={subtask.id} subtask={subtask} onSubmit={saveSubtask} donorNetPDFFields={donorNetPDFFields} />
                    // $FlowFixMe
                    : (
                        <Subtask
                            key={subtask.id}
                            subtask={subtask}
                            onSelect={handleSelectSubtask}
                            leftActions={(
                                <div className="subtask-actions-left">
                                    {[
                                        <FontIcon
                                            key={`${subtask.id}-up`}
                                            value="arrow_upward"
                                            className={`arrow ${subtaskIndex > 0 ? 'active' : 'inactive'}`}
                                            onClick={
                                                () => subtaskIndex > 0 && moveSubtask(subtask, subtaskIndex - 1)
                                            }
                                        />,
                                        <FontIcon
                                            key={`${subtask.id}-down`}
                                            value="arrow_downward"
                                            className={`arrow ${subtaskIndex < subtasks.length - 1 ? 'active' : 'inactive'}`}
                                            onClick={
                                                () => subtaskIndex < subtasks.length - 1 && moveSubtask(subtask, subtaskIndex + 1)
                                            }
                                        />
                                    ]}
                                </div>
                            )}
                            rightActions={(
                                <div className="subtask-actions-right">
                                    <Button
                                        key={`${subtask.id}-delete`}
                                        icon="delete"
                                        flat
                                        onClick={
                                            () => handleDeleteSubtask(subtask)
                                        }
                                    />
                                </div>
                            )}
                        />
                    )
            ))}

            <Input type="text" placeholder="Add Field" onFocus={addSubtask} />
        </div>
    );
}

function SubtaskForm({ subtask, onSubmit, donorNetPDFFields, }: { subtask: SubtaskType, onSubmit: (subtask: SubtaskType) => void, donorNetPDFFields: any }) {
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | void>(undefined);
    const displayOnItemAttribute = useMemo(() => subtaskAttributes[2], []);
    const defaultAttributes = useMemo(() => [displayOnItemAttribute], [displayOnItemAttribute]);
    const {
        title, uiType, responseOptions, attributes, displayAs, mapping,
    } = subtask;
    const hasNoAttributes = !attributes || attributes.length === 0;
    const [selectedAttributes, setSelectedAttributes] = useState(hasNoAttributes ? defaultAttributes : attributes);
    const isMultipleUiType = multipleOptionsUiTypes.includes(uiType);

    const donorNetPDFFieldOptions = donorNetPDFFields ? donorNetPDFFields.map((field) => ({ value: field.id, label: field.label, })) : [];

    const saveSubtask = useCallback((update: $Shape<SubtaskType>) => {
        onSubmit({
            ...subtask,
            ...update,
        });
    }, [onSubmit, subtask]);

    useEffect(() => {
        if (hasNoAttributes) {
            saveSubtask({ attributes: defaultAttributes, });
        }
    }, [saveSubtask, hasNoAttributes, defaultAttributes]);

    const addOption = () => {
        const newOptionIndex = responseOptions.length;
        saveSubtask({ responseOptions: [...responseOptions, ''], });
        setSelectedOptionIndex(newOptionIndex);
    };

    const saveOption = (updatedOption: FieldOption) => {
        saveSubtask({
            responseOptions: responseOptions.map(
                (option, optionIndex) => (optionIndex === selectedOptionIndex ? updatedOption : option)
            ),
        });
        setSelectedOptionIndex(undefined);
    };

    const moveOption = (currentIndex: number, newIndex: number) => {
        saveSubtask({
            responseOptions: moveArrayItem<FieldOption>(responseOptions, currentIndex, newIndex),
        });
    };

    const deleteOption = (deleteOptionIndex: number) => {
        saveSubtask({
            responseOptions: responseOptions.filter(
                (_option, optionIndex) => optionIndex !== deleteOptionIndex
            ),
        });

        if (selectedOptionIndex === deleteOptionIndex) {
            setSelectedOptionIndex(undefined);
        }
    };

    return (
        <div className="subtask-form">
            <div className="field-inputs">
                <Input
                    autoFocus
                    className="field-title"
                    type="text"
                    label="Field Title"
                    onChange={(titleChange) => saveSubtask({ title: titleChange, })}
                    value={title}
                />
                <Dropdown
                    auto
                    key={uiType}
                    label="Type"
                    onChange={(uiTypeChange) => saveSubtask({ uiType: uiTypeChange, })}
                    source={subtaskTypes}
                    value={uiType}
                    required
                />
                {selectedAttributes.map((attribute) => attribute.value).includes('displayOnItem') ? (
                    <Input
                        type="text"
                        label="Display As"
                        value={displayAs}
                        onChange={(displayAsChange) => saveSubtask({ displayAs: displayAsChange, })}
                    />
                ) : <div />}
                <Select
                    className="field-attributes"
                    isMulti
                    options={subtaskAttributes}
                    value={selectedAttributes}
                    placeholder="Attributes"
                    onChange={(fieldAttributeChange) => {
                        setSelectedAttributes(fieldAttributeChange);
                        saveSubtask({ attributes: fieldAttributeChange, });
                    }}
                />
                <Select
                    className="field-attributes"
                    options={donorNetPDFFieldOptions}
                    placeholder="PDF Data"
                    value={donorNetPDFFieldOptions.find((option) => option.value === mapping)}
                    onChange={(mappingChange) => {
                        saveSubtask({ mapping: mappingChange?.value, });
                    }}
                    isClearable
                />
            </div>
            {isMultipleUiType ? (
                <div>
                    <h4>Options</h4>
                    <ul className="subtask-option-list">
                        {responseOptions.map((option, idx) => (selectedOptionIndex === idx
                            ? (
                                <li key={idx.toString()} className="subtask-option-form-container">
                                    <SubtaskOptionForm option={option} onSubmit={saveOption} />
                                </li>
                            )
                            : (
                                <li key={idx.toString()}>
                                    <SubtaskOption
                                        option={option}
                                        onSelect={() => setSelectedOptionIndex(idx)}
                                        leftActions={(
                                            <div className="subtask-actions-left">
                                                {[
                                                    <FontIcon
                                                        key={`${idx.toString()}-up`}
                                                        value="arrow_upward"
                                                        className={`arrow ${idx > 0 ? 'active' : 'inactive'}`}
                                                        onClick={
                                                            () => idx > 0 && moveOption(idx, idx - 1)
                                                        }
                                                    />,
                                                    <FontIcon
                                                        key={`${idx.toString()}-down`}
                                                        value="arrow_downward"
                                                        className={`arrow ${idx < responseOptions.length - 1 ? 'active' : 'inactive'}`}
                                                        onClick={
                                                            () => idx < responseOptions.length - 1 && moveOption(idx, idx + 1)
                                                        }
                                                    />
                                                ]}
                                            </div>
                                        )}
                                        rightActions={(
                                            <div className="subtask-actions-left">
                                                {[
                                                    <Button
                                                        key={`${idx.toString()}-delete`}
                                                        icon="delete"
                                                        flat
                                                        onClick={
                                                            () => deleteOption(idx)
                                                        }
                                                    />
                                                ]}
                                            </div>
                                        )}
                                    />
                                </li>
                            )))}
                    </ul>
                    <Input type="text" placeholder="Add Option" onFocus={addOption} />
                </div>
            ) : null}
        </div>
    );
}

function Section({
    section, onSelect, leftActions, rightActions,
}: { section: TaskSectionType, onSelect: (selectedSection: TaskSectionType) => void, leftActions: Node, rightActions: Node }) {
    const noSectionTitle = section.title.length < 1;

    return (
        <section>
            <div className="section has-left-actions has-right-actions">
                {leftActions}

                <span onClick={onSelect} onKeyPress={onSelect} tabIndex="0" role="button">
                    <h2 className={noSectionTitle ? 'no-section-title' : ''}>{noSectionTitle ? '(Untitled Section)' : section.title}</h2>
                </span>

                {rightActions}
            </div>

            <div onClick={onSelect} onKeyPress={onSelect} tabIndex="0" role="button">
                <ul className="task-subtask-list">
                    {section.fields.map((subtask) => (
                        <li key={subtask.id}>
                            <Subtask subtask={subtask} />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}

function displaySubtaskType(subtask: SubtaskType): string {
    const { uiType, responseOptions, } = subtask;
    const options = responseOptions.length > 0 ? ` – ${responseOptions.join(', ')}` : '';
    return `${subtaskTypeLabel[uiType]}${options}`;
}

function displaySubtaskAttributes(subtask: SubtaskType): string {
    const { attributes, } = subtask;
    const labels = attributes.map((attribute) => attribute.label);
    return `${labels.join(', ')}`;
}

function Subtask({
    subtask, onSelect, leftActions, rightActions,
}: {
    subtask: SubtaskType,
    onSelect?: (subtask: SubtaskType) => *,
    leftActions?: Node,
    rightActions?: Node,
}) {
    const { title, } = subtask;

    const selectSubtask = onSelect ? () => onSelect(subtask) : () => null;
    const selectableProps = onSelect ? {
        onClick: selectSubtask,
        onKeyPress: selectSubtask,
        role: 'button',
        tabIndex: '0',
    } : {};

    return (
        <div className={`subtask-content${leftActions ? ' has-left-actions' : ''}${rightActions ? ' has-right-actions' : ''}`}>
            {leftActions}

            <div className="subtask-title-section" {...selectableProps}>
                <h3 className="subtask-title">{title}</h3>
                <p className="subtask-type">{displaySubtaskType(subtask)}</p>
                <p className="subtask-attributes">
                    {displaySubtaskAttributes(subtask)}
                </p>
            </div>

            {rightActions}
        </div>
    );
}

// eslint react/require-default-props
Subtask.defaultProps = {
    onSelect: undefined,
    leftActions: undefined,
    rightActions: undefined,
};

function SubtaskOption({
    option, onSelect, leftActions, rightActions,
}: {
    option: FieldOption,
    onSelect?: (option: FieldOption) => void,
    leftActions?: any, // how do you type a React.Node prop in Flow?
    rightActions?: any, // how do you type a React.Node prop in Flow?
}) {
    const selectOption = onSelect ? () => onSelect(option) : () => null;
    const selectableProps = onSelect ? {
        onClick: selectOption,
        onKeyPress: selectOption,
        role: 'button',
        tabIndex: '0',
    } : {};

    return (
        <div className={`option-content${leftActions ? ' has-left-actions' : ''}${rightActions ? ' has-right-actions' : ''}`}>
            {leftActions}

            <p className="option-title" {...selectableProps}>
                {option.trim().length > 0 ? option : <em>(blank)</em>}
            </p>

            {rightActions}
        </div>
    );
}

function SubtaskOptionForm({ option, onSubmit, }: { option: FieldOption, onSubmit: (option: FieldOption) => void }) {
    const [title, setTitle] = useState<string>(option);
    const ref = useRef<HTMLInputElement | null>(null);
    const hasTitle = title.length > 0;

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [option]);

    return (
        <>
            <Input
                innerRef={(input) => { ref.current = input; }}
                type="text"
                label="Option Title"
                onChange={setTitle}
                value={title}
            />
            <Button
                style={hasTitle ? ApplicationStyles.button : ApplicationStyles.buttonDisabled}
                label="Save Option"
                onClick={() => {
                    onSubmit(title);
                    setTitle('');
                }}
                disabled={!hasTitle}
            />
        </>
    );
}

// eslint react/require-default-props
SubtaskOption.defaultProps = {
    onSelect: undefined,
    leftActions: undefined,
    rightActions: undefined,
};
